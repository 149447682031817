@use 'styles/base/mixins';

.root {
  margin: 0 0 var(--spacing-10);
}

.image {
  width: 100%;
}

.info {
  padding: var(--spacing-04);
  color: var(--white);
  background-color: var(--black);

  @include mixins.breakpoint(s) {
    padding: var(--spacing-06);
  }
}

.copyright {
  color: var(--gray-light);
}
