@use 'styles/base/mixins';

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(var(--black-rgb) / 40%);
  transform: translate(-50%, -50%);
}

.box {
  width: 2.75em;
  height: 1.5em;
  margin-right: 0.35em;
  opacity: 0;
}

.box1 {
  background-color: var(--blue);
  animation: box1 1.4s infinite;
}

.box2 {
  background-color: var(--blue-light);
  animation: box2 1.4s infinite;
}

.box3 {
  background-color: var(--primary);
  animation: box3 1.4s infinite;
}

@keyframes box1 {
  0% {
    opacity: 0;
  }

  12.5%,
  50% {
    opacity: 1;
  }

  62.5%,
  100% {
    opacity: 0;
  }
}

@keyframes box2 {
  0%,
  12.5% {
    opacity: 0;
  }

  25%,
  62.5% {
    opacity: 1;
  }

  75%,
  100% {
    opacity: 0;
  }
}

@keyframes box3 {
  0%,
  25% {
    opacity: 0;
  }

  37.5%,
  75% {
    opacity: 1;
  }

  87.5%,
  100% {
    opacity: 0;
  }
}

.videoWithRelated {
  position: relative;
  margin-bottom: var(--spacing-10);
  padding-top: 75%;
  overflow: hidden;
  background-color: var(--black);

  .videoWithRelatedInside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .loaderPadding {
      padding-bottom: var(--spacing-20);
    }
  }
}
