@use 'styles/base/mixins';

.button {
  background-color: var(--white);
  cursor: pointer;
  border: 0;

  @include mixins.devices(mobile-only) {
    display: flex;
    gap: var(--spacing-03);
    align-items: flex-start;
  }
}
