@use 'styles/base/mixins';

.svg {
  display: block;
  background-color: var(--gray-bright);
}

.image {
  @include mixins.breakpoint(xs-only) {
    display: none;
  }
}
