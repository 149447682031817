@use 'styles/base/mixins';

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1500;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden scroll;
  background-color: var(--white);
  -webkit-overflow-scrolling: touch;
}

.modal {
  display: block;
  width: unset;
  max-width: 600px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-04);
  background-color: var(--white);
  border: unset;

  @include mixins.breakpoint(s) {
    min-height: unset;
    padding: var(--spacing-20) 0 0;
  }

  @include mixins.breakpoint(l) {
    max-width: 720px;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--spacing-12);

  @include mixins.breakpoint(s) {
    margin-bottom: var(--spacing-08);
  }
}

.closeButton {
  display: flex;
  align-items: center;
  min-width: var(--spacing-12);
  height: var(--spacing-12);
  padding: 0;
  color: var(--gray-dark);
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  transition: color var(--transition-default);

  &:hover {
    color: var(--black);
  }
}
