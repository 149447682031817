@use 'styles/base/mixins';

.section {
  container-type: inline-size;
}

.grid {
  display: grid;
  gap: var(--spacing-08) var(--spacing-05);

  &.fiveColumns {
    row-gap: var(--spacing-02);

    @include mixins.devices(tablet) {
      grid-template-columns: repeat(5, minmax(10px, 1fr));
      column-gap: var(--spacing-04);
    }
  }

  @supports (container-type: inline-size) {
    @container (min-width: 768px) {
      gap: var(--spacing-12) var(--spacing-10);

      &.twoColumns {
        grid-template-columns: repeat(2, minmax(10px, 1fr));
      }

      &.threeColumns {
        grid-template-columns: repeat(3, minmax(10px, 1fr));
      }
    }
  }

  @supports not (container-type: inline-size) {
    @include mixins.devices(tablet) {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: var(--spacing-12) var(--spacing-10);

      &.fiveColumns {
        grid-template-columns: repeat(5, minmax(10px, 1fr));
        column-gap: var(--spacing-04);
      }
    }

    @include mixins.devices(desktopXl) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  > div[data-id='lead'] {
    @include mixins.devices(tablet) {
      grid-column: span 3;
      margin-bottom: calc(var(--spacing-04) * -1);
    }
  }

  > div:empty {
    display: none;
  }
}
