@use 'styles/base/mixins';

.root {
  margin-bottom: var(--spacing-06);

  :global(.tik4) {
    --t4-secondary: var(--gray-light);
    --t4-muted: var(--gray-dark);
    --t4-link: var(--black);
    --t4-txt: var(--black);
    --t4-txt-xl-lh: 36px;
    --t4-ev-bg: transparent;
    --t4-rounded: 0;
  }

  :global(.tik4-blog) {
    margin-left: 10px;
    border-left: 2px dashed #d1d1d1;
  }

  :global(.tik4-event) {
    padding-bottom: 40px;
    border-top: 0;

    &:last-of-type {
      padding-bottom: 0;

      :global(.tik4-event__body) {
        border-bottom: 0;
      }
    }
  }

  :global(.tik4-event__body) {
    margin-left: 20px;
    padding: 0 0 40px;
    border-bottom: 2px solid #f2f2f2;
  }

  :global(.tik4-event--highlight) {
    background: transparent;

    &::before {
      display: none;
    }

    :global(.tik4-content-block) {
      padding: 20px;
      background: var(--gray-bright);
      border-left: 4px solid var(--primary);
    }
  }

  :global(.tik4-event--pin) {
    :global(.tik4-meta__ts__time__val) {
      display: block;
    }
  }

  :global(.tik4-meta) {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: -30px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea123b' viewBox='0 0 24 24'%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      border-radius: 50%;
    }
  }

  :global(.tik4-meta__ts) {
    flex-direction: row;
  }

  :global(.tik4-meta__ts__icons) {
    display: none;
  }

  :global(.tik4-meta__ts__date),
  :global(.tik4-meta__ts__time) {
    color: var(--black);
    font-size: 13px;
    font-weight: normal;
    line-height: 1.25;
  }

  :global(.tik4-meta__ts__date) {
    &::after {
      content: ' ';
      white-space: pre;
    }
  }

  :global(.tik4-author__name) {
    margin-bottom: 10px;
    color: #8b8b8b;
    font-size: 12px;
    font-weight: bold;
  }

  :global(.tik4-content-block) {
    margin-top: 1em;
  }

  :global(.tik4-rich-text) {
    line-height: 36px;
  }

  :global(.tik4-media-body) {
    font-size: 0.8em;
    font-style: normal;
  }

  :global(.tik4-media-image) {
    position: relative;

    :global(.tik4-media-body) {
      position: absolute;
      bottom: 1%;
      left: 1%;
      z-index: 1;
      margin: 0;
      color: #ccc;
      text-shadow: 0 1px rgb(0 0 0 / 70%);
    }
  }

  :global(.tik4-we-simple) {
    max-width: 100% !important;
  }

  :global(.tik4-we-simple__container) {
    padding: 10px;
    background: var(--gray-bright);
    border: 0;

    @include mixins.devices(tablet) {
      display: flex;
    }
  }

  :global(.tik4-we-simple__thumb) {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 5px;

    @include mixins.devices(tablet) {
      max-width: 222px;
      height: 125px;
      margin-bottom: 0;
    }

    > img {
      display: block;
      width: 100%;
      height: 100%;
      border-left: 4px solid var(--primary);
      object-fit: cover;
    }
  }

  :global(.tik4-we-simple__content) {
    padding: 5px 0 0 !important;

    @include mixins.devices(tablet) {
      padding: 5px 0 0 13px !important;
    }
  }

  :global(.tik4-we-simple__title) {
    color: var(--gray-dark) !important;
    font-size: 13px !important;
    font-weight: normal;
    text-decoration: none !important;
  }

  :global(.tik4-we-simple__description) {
    color: var(--black);
    font-size: 18px;
    font-weight: bold;
  }

  :global(.tik4-we-simple__meta) {
    display: none;
  }

  :global(.tik4-load-more-bottom) {
    margin-top: 0;
    padding: 0 0 0 20px;
  }

  :global(a.tik4-button.tik4-button--primary),
  :global(button.tik4-button.tik4-button--primary) {
    align-items: center;
    height: 61px;
    background-color: var(--black);
    font-size: 17px;
    font-weight: normal;

    > svg {
      display: none;
    }
  }

  :global(.tik4-we-toggle__checkbox.tik4-we-toggle__checkbox) {
    &:checked ~ ::after {
      background-color: var(--gray-dark);
    }
  }

  :global(.tik4-by) {
    display: none;
  }
}
