@use 'styles/base/mixins';

.imageWrapper {
  min-height: 100px;

  @include mixins.breakpoint(s) {
    min-height: 120px;
  }

  @include mixins.breakpoint(l) {
    min-height: 180px;
  }

  .image {
    width: 100%;
    overflow: hidden;

    &.mobile {
      display: block;

      @include mixins.breakpoint(s) {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @include mixins.breakpoint(s) {
        display: block;
      }
    }
  }
}

.menu {
  margin-top: var(--spacing-04);
  margin-bottom: var(--spacing-08);
  background-color: var(--black);

  @include mixins.breakpoint(s) {
    margin-top: var(--spacing-10);
  }
}

.navigation {
  display: flex;
  padding: 14px;
  overflow-y: auto;
  scrollbar-color: var(--black) var(--white);
  scrollbar-width: thin;

  @include mixins.breakpoint(s) {
    padding: var(--spacing-06);
  }
}
