@use 'styles/base/mixins';

.title {
  @include mixins.text-medium;

  margin-bottom: var(--spacing-06);
  font-weight: 700;
}

.firstLevelList {
  padding: 0;
}

.firstLevel:not(.initiallyOpen) {
  margin-bottom: var(--spacing-03);
}
