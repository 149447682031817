@use 'styles/base/mixins';

.root {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-06);

  @include mixins.breakpoint(s) {
    margin-bottom: var(--spacing-08);
  }
}

.title {
  @include mixins.text-medium;

  font-weight: 700;

  @include mixins.breakpoint(l) {
    @include mixins.text-large;
  }
}

.logo {
  margin-right: var(--spacing-03);
}
