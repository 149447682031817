@use 'styles/base/mixins';

.root {
  padding: var(--spacing-04);

  @include mixins.breakpoint(l) {
    padding: var(--spacing-16);
  }
}

.loading {
  aspect-ratio: 16 / 9;
}

.enableConsent {
  margin-bottom: var(--spacing-04);
  padding: var(--spacing-08);
  background-color: var(--gray-ad);
}

.consentCta {
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  border: none;
}
