@use 'styles/base/mixins';

@mixin display-ad {
  /* stylelint-disable */
  :global(#outstream_div) {
    // can't be kebab-case because of fixed ID from the Ad Alliance
    /* stylelint-enable */
    width: 100%;
  }

  &.adwrapper--opinary-automation-placeholder {
    display: block;
  }

  .teaser-item {
    display: flex;
    flex-direction: row;
    font-family: 'RTL United', Arial, sans-serif;

    .teaser-image {
      margin-right: var(--spacing-04);

      .promo-item {
        width: 124px;
      }
    }

    .teaser-text {
      flex: 1 1 auto;

      .teaser-kicker {
        margin-bottom: var(--spacing-01);
        color: var(--gray-dark);
        font-size: 13px;
      }

      .teaser-headline {
        margin-bottom: var(--spacing-01);
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
      }

      .teaser-video-info {
        color: var(--gray-dark);
        font-size: 13px;
      }

      .teaser-additional-link {
        color: var(--gray-dark);
        font-size: 13px;
        text-decoration: underline;
      }
    }

    @include mixins.breakpoint(xs-only) {
      .teaser-item.teaser-consider-breakpoint {
        flex-direction: column;

        .teaser-image {
          position: relative;
          margin: 0 0 var(--spacing-02);

          .promo-item {
            width: 100%;
          }
        }

        .teaser-text {
          margin-right: 0;

          .teaser-headline {
            font-size: 24px;
          }
        }
      }

      .teaser-item.teaser-news.teaser-consider-breakpoint .teaser-image {
        .promo-item {
          border-left: none;
        }
      }

      .teaser-item.teaser-news.teaser-consider-breakpoint .teaser-kicker {
        display: none;

        &.teaser-kicker-desktop {
          position: absolute;
          bottom: var(--spacing-03);
          left: var(--spacing-03);
          display: block;
          max-width: calc(100% - var(--spacing-06));
          border-left: 4px solid var(--primary);

          h3 {
            padding: var(--spacing-01);
            color: var(--black);
            background: var(--white);
            font-size: 13px;
            box-decoration-break: clone;
          }
        }
      }

      .teaser-item.teaser-neutral.teaser-consider-breakpoint .teaser-kicker {
        margin-bottom: var(--spacing-01);
      }
    }
  }
}

.default-viewport,
.teaser-ad {
  position: relative;
  display: flex;
  margin: 0 0 var(--spacing-16);

  @include mixins.breakpoint(s) {
    margin: 0 0 var(--spacing-20);
  }

  @include display-ad;
}

.mobile-viewport,
.mobile {
  position: relative;
  display: flex;
  margin: 0 0 var(--spacing-06);

  @include display-ad;
}

.desktop-viewport,
.desktop {
  position: relative;
  display: flex;
  margin: 0 0 var(--spacing-08);

  @include display-ad;
}

.center {
  flex-wrap: wrap;
  place-content: center center;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}

.placeholder {
  justify-content: center;
  align-items: center;
  min-height: 50px;
  color: var(--white);
  background: linear-gradient(
    147deg,
    rgb(215 3 100 / 100%) 0,
    rgb(117 43 119 / 100%) 55%,
    rgb(55 118 183 / 100%) 88%,
    rgb(9 174 231 / 100%) 100%
  );
  text-align: center;

  /* taken from guj-placeholder */
  border: 5px solid #752b77;
  border-image: linear-gradient(
      40deg,
      rgb(9 174 231 / 100%) 0%,
      rgb(55 118 183 / 100%) 55%,
      rgb(117 43 119 / 100%) 88%,
      rgb(215 3 100 / 100%) 100%
    )
    1;
}

.minHeightAuto {
  min-height: auto;
  margin-bottom: 0;

  .placeholder {
    min-height: 100px;
  }
}

.adaPrediction {
  .placeholder {
    height: 100%;
  }
}

.preserveSpaceStatic {
  margin: 0 0 var(--spacing-08) 0;

  @include mixins.breakpoint(s) {
    margin: 0 0 var(--spacing-10) 0;
  }
}

.outbrainWrapper {
  display: block;
  margin: 0;
}

.adPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--gray-2);
  background-color: var(--gray-ad);

  @include mixins.text-small;
}
