@use 'styles/base/mixins';

.grid {
  display: grid;
  gap: var(--spacing-16) var(--spacing-12);

  @include mixins.breakpoint(s) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
