@use 'styles/base/mixins';

.teaser {
  position: relative;
}

.imageWrapperInLead {
  position: relative;
}

.imageWrapperDefault {
  position: absolute;
}

.imageWrapper {
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  background-color: var(--gray-bright);
}

.image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.link {
  @include mixins.stretched-link;
}

.h3 {
  position: relative;
}

.videoTag {
  margin-top: var(--spacing-02);
  color: var(--gray-dark);
}

.videoButton {
  position: absolute;
  bottom: var(--spacing-03);
  left: var(--spacing-03);
  display: flex;
  gap: var(--spacing-03);
  align-items: center;
  padding: 0 var(--spacing-03);
  color: var(--white);
  background-color: var(--black);
  font-size: 15px;
  line-height: 1.8;
}

.teaserButton {
  display: inline-block;
  justify-self: flex-start;
  margin-top: var(--spacing-06);
  padding: var(--spacing-02) var(--spacing-05);
  font-size: 17px;
  line-height: 1.65;
  border: 1px solid #979797;

  &.filled {
    color: var(--white);
    background-color: var(--black);
    border-color: var(--black);
  }
}

.lead {
  .imageWrapper {
    margin-bottom: var(--spacing-03);

    @include mixins.devices(tablet) {
      margin-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(30deg, var(--black) 11%, rgb(0 0 0 / 0%) 70%);
        opacity: 0.47;
      }
    }
  }

  .image,
  .fallbackImage {
    width: 100%;
    height: 343px;

    @include mixins.devices(tablet) {
      height: 480px;
    }
  }

  .textWrapper {
    @include mixins.devices(tablet) {
      position: absolute;
      right: var(--spacing-10);
      bottom: var(--spacing-10);
      left: var(--spacing-10);
      max-width: 520px;
    }
  }

  .kicker {
    background-color: var(--white);
    border-left: 4px solid var(--primary);

    @include mixins.devices(mobile-only) {
      position: absolute;
      top: calc(var(--spacing-14) * -1);
      left: var(--spacing-04);
      padding: var(--spacing-01);
    }

    @include mixins.devices(tablet) {
      display: inline-block;
      margin-bottom: var(--spacing-02);
      padding: var(--spacing-01) var(--spacing-02);
      font-size: 17px;
      line-height: 1.65;
    }
  }

  .headline {
    line-height: 1.3;

    @include mixins.devices(tablet) {
      color: var(--white);
      font-size: 48px;
      line-height: 1.15;
    }
  }

  .videoTag {
    margin-top: var(--spacing-02);

    @include mixins.devices(tablet) {
      color: var(--gray-light);
    }
  }
}

.default {
  @supports (container-type: inline-size) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
  }

  @supports not (container-type: inline-size) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
  }

  .imageWrapper {
    grid-column-start: 1;
    grid-row-start: 1;
    aspect-ratio: 16 / 9;
  }

  .link {
    grid-column-start: 1;
    grid-row-start: 1;

    @include mixins.container(mobile-only) {
      gap: var(--spacing-03);
      align-items: flex-start;
    }
  }

  .kickerWrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--spacing-02);
    padding: var(--spacing-03);

    &::before {
      content: '';
      display: block;
      padding-top: calc(56.25% - var(--spacing-03));
    }

    @supports (aspect-ratio: 16 / 9) {
      aspect-ratio: 16 / 9;

      &::before {
        display: none;
      }
    }
  }

  .kicker {
    padding: var(--spacing-01);
    background-color: var(--white);
    border-left: 4px solid var(--primary);
  }

  &.smallMobile {
    .imageWrapper {
      @include mixins.container(mobile-only) {
        position: relative;
        width: 124px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 4px;
          height: 100%;
          background-color: var(--primary);
        }
      }
    }

    .kickerWrapper {
      @include mixins.container(mobile-only) {
        margin-bottom: 0;
        padding: 0;
        aspect-ratio: unset;

        &::before {
          display: none;
        }
      }
    }

    .kicker {
      @include mixins.container(mobile-only) {
        @include mixins.line-clamp(2);

        margin-bottom: var(--spacing-01);
        padding: 0;
        color: var(--gray-dark);
        background-color: transparent;
        border-left: 0;
      }
    }

    .headline {
      @include mixins.container(mobile-only) {
        @include mixins.line-clamp(3);

        font-size: 17px;
        line-height: 1.25;
      }
    }

    .link {
      @include mixins.container(mobile-only) {
        display: flex;
      }
    }
  }
}

.sponsored {
  position: absolute;
  top: 10px;
  right: 10px;
}
