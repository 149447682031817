@use 'styles/base/mixins';

.footer-nav {
  display: grid;
  gap: var(--spacing-05);
  justify-content: space-between;
  margin-bottom: var(--spacing-10);

  @include mixins.breakpoint(s) {
    grid-template-columns: repeat(4, 1fr);
  }

  &-link {
    display: block;
    padding: 0;
    color: var(--gray-dark);
    background-color: transparent;
    line-height: 2;
    cursor: pointer;
    border: none;

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      color: var(--white);
      font-weight: bold;
    }
  }
}
