@use 'styles/base/mixins';

.search {
  display: grid;
  gap: var(--spacing-04);
  padding-bottom: var(--spacing-20);
}

.form {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  height: var(--spacing-12);
  margin: 0;
  padding: 0 var(--spacing-12);
  background-color: var(--gray-bright);
  font-size: 17px;
  line-height: var(--spacing-12);
  text-overflow: ellipsis;
  border: 2px solid transparent;
  border-bottom-color: var(--black);
  transition:
    border-color var(--transition-default),
    background-color var(--transition-default);
  appearance: none;

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  &:focus {
    background-color: var(--white);
    border-color: var(--black);
    outline: 0;
  }

  @include mixins.devices(tablet) {
    height: var(--spacing-14);
    padding: 0 var(--spacing-14);
    line-height: var(--spacing-14);
  }
}

.inputSearchIcon {
  position: absolute;
  top: 0;
  left: var(--spacing-04);
  height: 100%;
  pointer-events: none;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 var(--spacing-05);
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.headline {
  margin-bottom: var(--spacing-04);
  font-weight: bold;
}

.list {
  display: flex;
  gap: var(--spacing-06);
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.link {
  display: flex;
  gap: var(--spacing-02);
  align-items: center;
  color: var(--gray-dark);

  &:hover {
    text-decoration: underline;
  }
}
