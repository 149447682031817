.nav {
  display: flex;
  gap: var(--spacer-05);

  a {
    &:hover {
      color: var(--primary);
    }
  }

  .submenu {
    position: absolute;
    padding-top: calc(var(--spacer-05) / 2);
    padding-bottom: calc(var(--spacer-05) / 2);

    &:hover {
      display: flex;
    }
  }

  .submenu-item {
    display: flex;
    flex-direction: column;
  }

  .dropdown {
    position: relative;
    white-space: nowrap;

    nav {
      display: none;
    }

    &:hover {
      nav {
        display: flex;
      }
    }

    &::after {
      content: '▼';
      display: inline-flex;
      margin-left: var(--spacing-01);
    }
  }
}
