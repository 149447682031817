@use 'styles/base/mixins';

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-06);
  padding-bottom: var(--spacing-01);
  border-bottom: 2px solid var(--gray-bright);

  @include mixins.devices(mobile-only) {
    flex-wrap: wrap-reverse;
    grid-row-gap: var(--spacing-01);
  }

  div {
    display: flex;
    align-items: center;
  }

  &.link {
    &:hover {
      color: var(--gray-dark);
    }
  }
}

.icon {
  margin-left: var(--spacing-02);
}

.rtlPlus {
  margin-left: var(--spacing-02);
}
