@use 'styles/base/mixins';

.root {
  display: flex;
  gap: var(--spacing-03);
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--spacing-24);
}

.link {
  text-decoration: underline;

  &:hover {
    color: var(--gray-dark);
  }
}
