@use 'styles/base/mixins';

.logo {
  position: sticky;
  top: 0;
  z-index: 22;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: var(--spacing-06);
  padding-bottom: var(--spacing-06);
  background-color: var(--white);
  place-content: center center;

  @include mixins.breakpoint(s) {
    padding-top: var(--spacing-08);
    padding-bottom: var(--spacing-08);
  }
}

.mainNavigation {
  position: sticky;
  top: 0;
  z-index: 21;
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-06);
  overflow: hidden;
  background-color: var(--white);
  transition: top 0.3s ease-in-out;

  @include mixins.breakpoint(s) {
    display: inline-flex;
  }

  &.scrolled {
    top: 64px;

    @include mixins.breakpoint(s) {
      top: 84px;
    }
  }
}

.button {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding-bottom: 2px;
  border: none;
  border-radius: 100%;
}

.search {
  position: fixed;
  top: 13px;
  right: var(--spacing-06);
  z-index: 23;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding-bottom: 2px;
  color: var(--white);
  background-color: var(--black);
  cursor: pointer;
  border: none;
  border-radius: 100%;

  @include mixins.breakpoint(s) {
    position: sticky;
    top: 22px;
    display: inline-block;
    margin-left: -40px;
    padding-bottom: 0;
  }
}

.close {
  position: relative;
  display: inline-flex;
  justify-self: end;
  margin-top: var(--spacing-10);
  color: var(--white);
  background-color: #191919;
  cursor: pointer;
}

.menu {
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  overflow: scroll hidden;
  list-style-type: none;
  /* stylelint-disable-line */
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include mixins.breakpoint(xs-only) {
    padding-left: var(--spacing-06);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuItem {
  margin-right: var(--spacing-06);
  padding-bottom: var(--spacing-02);
  color: var(--gray-dark);
  white-space: nowrap;

  @include mixins.text-small;

  @include mixins.breakpoint(s) {
    margin-right: var(--spacing-10);

    @include mixins.text-medium;
  }
}

.active {
  color: var(--black);
  font-weight: 700;
  border-bottom: none !important;
}

.navLayer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  display: grid;
  grid-auto-rows: max-content;
  gap: var(--spacing-12);
  justify-self: end;
  width: 100%;
  height: 100vh;
  padding: 0 var(--spacing-06) var(--spacing-20);
  overflow: scroll;
  color: var(--white);
  background-color: #000;

  @include mixins.breakpoint(s) {
    max-width: 500px;
    padding: 0 var(--spacing-20);
  }
}

.naviLayerClosed {
  visibility: hidden;
}

.backdrop {
  display: none;

  @include mixins.breakpoint(s) {
    position: fixed;
    inset: 0;
    z-index: 1999;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.7;
  }
}
