@use 'styles/base/mixins';

.combiAd {
  display: none;
  flex-direction: column;

  @include mixins.devices(mobile-only) {
    display: flex;
  }

  > span {
    margin-left: auto;
    padding: 2px 5px 2px 8px;
    background-color: var(--gray-bright);
  }

  > div {
    display: flex;
    gap: var(--spacing-04);
    flex-direction: column;
    padding: var(--spacing-04) var(--spacing-02);
    background-color: var(--gray-bright);

    > div {
      min-height: 0 !important;
      margin: 0;
    }
  }
}
