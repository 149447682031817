@use 'styles/base/mixins';

.wrapper {
  display: inline-block;

  figure {
    float: left;
    width: 60%;
    max-width: 700px;
    margin: 0 var(--spacing-06) var(--spacing-02) 0;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    + h2,
    + p {
      margin-top: 0;
    }

    @include mixins.devices(mobile-only) {
      float: none;
      width: 100%;
      max-width: unset;
      margin: 0 0 var(--spacing-06) 0;
    }
  }
}
