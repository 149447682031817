@mixin line-mixin {
  width: 16px;
  height: 2px;
  background-color: var(--gray-dark);
}

.button {
  width: 16px;
  background: transparent;
  cursor: pointer;
  border: 0;
}

.inner {
  @include line-mixin;

  position: relative;
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.075s;

  &::before {
    @include line-mixin;

    content: '';
    position: absolute;
    top: -5px;
    display: block;
    transition:
      top 0.075s 0.12s ease,
      opacity 0.075s ease;
  }

  &::after {
    @include line-mixin;

    content: '';
    position: absolute;
    bottom: -5px;
    display: block;
    transition:
      bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &.active {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);

    &::before {
      top: 0;
      opacity: 0;
      transition:
        top 0.075s ease,
        opacity 0.075s 0.12s ease;
    }

    &::after {
      bottom: 0;
      transition:
        bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(-90deg);
    }
  }
}
