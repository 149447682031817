@use 'styles/base/mixins';

.text-4xl {
  font-size: 87px;
  font-weight: bold;
  line-height: 1.15;
}

.text-3xl {
  font-size: 60px;
  font-weight: bold;
}

.text-2xl {
  font-size: 32px;
  font-weight: bold;

  @include mixins.devices(tablet) {
    font-size: 46px;
  }
}

.text-xl {
  font-size: 32px;
  font-weight: bold;

  @include mixins.devices(tablet) {
    font-size: 40px;
  }
}

.text-l {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;

  @include mixins.devices(tablet) {
    font-size: 32px;
  }
}

.text-m {
  font-size: 24px;
  font-weight: bold;
}

.text-s {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
}

.text-xs {
  font-size: 17px;
  line-height: 1.3;

  @include mixins.devices(tablet) {
    font-size: 20px;
  }
}

.text-2xs {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.25;

  @include mixins.devices(tablet) {
    font-size: 18px;
  }
}

.text-3xs {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
}

.text-4xs {
  font-size: 13px;
  line-height: 1.25;
}

.text-5xs {
  font-size: 12px;
  line-height: 1.42;
}

.text-6xs {
  font-size: 10px;
  font-weight: bold;
}

.text-default {
  font-size: 17px;
  font-weight: normal;
  line-height: 1.65;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.underlined {
  text-decoration: underline;
}
