@use 'styles/base/mixins';

.logo {
  display: block;
  height: 16px;

  &.teaser {
    height: 12px;
  }

  &.header {
    height: 16px;

    @include mixins.breakpoint(s) {
      height: 20px;
    }
  }

  &.tiny {
    height: 10px;
  }

  @include mixins.breakpoint(s) {
    &.small {
      height: 25px;
    }

    &.medium {
      height: 28px;
    }
  }
}
