.sponsored {
  padding: var(--spacing-01);
  background-color: var(--gray-light);
  font-size: 10px;
}

.SponsoredIconFull {
  position: relative;

  hr {
    position: absolute;
    top: -2%;
    z-index: -1;
    width: 100%;
  }
}

.withMargin {
  margin: 30px 0;
}

.SponsoredIconFullWrapper {
  display: flex;
  justify-content: center;
}

.SponsoredIconFullText {
  border-color: var(--white);
  border-style: solid;
  border-width: 0 20px;
  border-image: initial;
}
