@use 'styles/base/mixins';

.header {
  position: sticky;
  top: 0;
  z-index: 1001;
  max-width: 1200px;
  margin: 0 var(--spacing-04);
  margin-bottom: 79px;
  background: var(--white);

  @include mixins.devices(tablet) {
    margin: 0 auto 105px;
  }

  @include mixins.devices(mobile-only) {
    margin: 0 0 60px;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid var(--gray-bright);

  @include mixins.devices(mobile-only) {
    > button {
      padding: var(--spacing-04);
    }
  }
}

.bottom {
  border-bottom: 2px solid var(--gray-bright);
}

.logo {
  flex-grow: 1;

  @include mixins.devices(mobile-only) {
    padding: var(--spacing-04);
  }
}

@mixin item-border {
  content: '';
  position: absolute;
  bottom: -1px;
  left: var(--spacing-04);
  width: calc(100% - (var(--spacing-04) * 2));
  height: 2px;
  background-color: var(--black);
  transition: transform 250ms ease-in-out 0s;
  transform: scaleX(0);
}

@mixin hover-menu {
  top: 50px;
  left: var(--spacing-04);
  flex-direction: column;
  width: auto;
  max-width: none;
  max-height: 75vh;
  padding: var(--spacing-04);
  overflow-y: scroll;
  box-shadow: rgb(0 0 0 / 5%) 0 0 14px 3px;
  opacity: 1;
  transition:
    transform 250ms ease-in-out 0s,
    opacity 250ms ease-in-out 0s;
  transform: scale(1);
  transform-origin: center top 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > li {
    margin: var(--spacing-06) 0 0;
    padding: 0;

    &:first-child {
      margin: 0;
    }

    > span > a {
      display: block;
      padding: 0;
    }

    &:hover {
      color: var(--black);
    }

    &.hidden {
      display: block;
    }
  }

  ul,
  a::after,
  .menuRest {
    display: none !important;
  }
}

@mixin separator {
  content: '';
  position: absolute;
  right: 0;
  width: 2px;
  height: var(--spacing-06);
  background-color: var(--gray-bright);
}

.headerNav {
  display: flex;
  color: var(--gray-dark);
  white-space: nowrap;
}

.searchButton {
  padding: 0 var(--spacing-04);
  color: var(--gray-dark);
  background-color: var(--white);
  cursor: pointer;
  border: none;
}

.backIcon {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-04) 0 0;

  &::after {
    @include separator;
  }
}

.menuList {
  display: flex;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--white);
  list-style-type: none;

  &:not(.menuListLevel0) {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    border-bottom: 2px solid var(--gray-bright);
    opacity: 0;
    transition: opacity 250ms ease-in-out 0s;
    transform: scaleY(0);

    &.hasActiveItemWithChildren > .menuItem:not(.active) {
      display: none;
    }

    .isActiveWithChildren {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      > span {
        position: relative;

        &::after {
          @include separator;
        }

        > a {
          color: var(--black);
        }
      }

      > .menuList {
        position: relative;
        z-index: 1;
        border: none;
        opacity: 1;
        transform: scale(1);
      }

      .menuItem a {
        padding-left: var(--spacing-04);
      }

      ~ .menuRest {
        display: none;
      }
    }

    &.hasActiveGrandChildWithChildren {
      > .backIcon,
      > .isActiveWithChildren > span {
        display: none;
      }
    }

    > .hasActiveChild > span > a::after {
      content: none;
    }
  }
}

.menuListLevel1 > .menuItem:first-child {
  > span > a {
    padding-left: 0;

    &::after {
      left: 0;
      width: calc(100% - (var(--spacing-04)));
    }
  }

  &:hover .menuList {
    left: 0 !important;
  }
}

.siteLoaded > .active > .menuList {
  z-index: 1;
  opacity: 1;
  transform: scaleY(1);
}

.menuRest {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 var(--spacing-04);
  cursor: pointer;

  &::before {
    @include separator;

    left: 0;
  }

  &::after {
    @include item-border;

    left: 0;
    width: 100%;
  }

  ul {
    opacity: 0;
    transform: scale(0);
  }

  &:hover {
    ul {
      @include hover-menu;

      top: 78px;
      right: 0;
      left: auto;
    }

    &::after {
      transform: scaleX(1);
    }
  }
}

.accordionIcon {
  margin-right: var(--spacing-04);
  padding: 0;
  background: none;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease-out;
}

.hidden,
.accordionIcon {
  display: none;
}

@include mixins.devices(tablet) {
  .desktopHidden,
  .burgerMenu {
    display: none;
  }

  .menuListLevel0 > .menuItem {
    background-color: var(--white) !important;
  }

  .menuListLevel1 {
    top: 79px;
  }

  .menuItem {
    margin: var(--spacing-07) 0;

    > span > a {
      position: relative;
      padding: var(--spacing-07) var(--spacing-04);
      font-size: 17px;
      cursor: pointer;

      &::after {
        @include item-border;
      }
    }

    &.active > span > a::after,
    &:hover > span > a::after {
      transform: scaleX(1);
    }
  }

  .menuList:not(.menuListLevel0) > .menuItem {
    position: relative;

    &:not(.active):hover > .menuList {
      @include hover-menu;
    }
  }
}

@include mixins.devices(mobile-only) {
  .mobileHidden {
    display: none;
  }

  .burgerMenu {
    margin-right: var(--spacing-04);
  }

  .headerNav {
    flex-basis: 100%;
    flex-direction: column;
    color: var(--black);
    text-align: center;
    border-top: 2px solid var(--gray-bright);
  }

  .backIcon {
    padding: 0 var(--spacing-04);
    padding-right: var(--spacing-04);
  }

  .active > .menuList {
    z-index: 1;
    opacity: 1;
  }

  .menuList {
    overflow: scroll hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &:not(.menuListLevel0) {
      > .menuItem > span > a {
        display: block;
        padding: var(--spacing-04) var(--spacing-02);
      }

      .isActiveWithChildren > span::after {
        top: var(--spacing-03);
        right: -6px;
        z-index: 2;
      }
    }
  }

  .menuListLevel0 > .menuItem {
    flex: 1 1;

    > span > a,
    > span > div {
      display: block;
      padding: var(--spacing-04) 0;
    }
  }

  .menuListLevel1 {
    top: 107px;
  }

  .menuItem {
    &.active {
      > span > a,
      > span > div {
        color: var(--black);
      }
    }

    > span > a,
    > span > div {
      font-size: 13px;
    }
  }

  .burgerMenuOpen {
    .backIcon {
      display: none;
    }

    .menuListLevel1 {
      display: block;
      height: 100vh;
      padding: var(--spacing-10) var(--spacing-07) 150px;
      overflow: scroll;
      text-align: left;
      transition: none;

      > .menuItem {
        position: relative;
        display: block !important;
        border-top: 1px solid var(--gray-light);

        > span {
          > a {
            width: 100%;
            padding: var(--spacing-04) 0;
          }

          > .accordionIcon {
            display: block;
          }

          &::after {
            content: none;
          }
        }

        &.accordionItemOpen {
          &::before {
            content: '';
            position: absolute;
            left: calc(var(--spacing-07) * -1);
            width: 4px;
            height: 100%;
            background: var(--black);
          }

          .accordionIcon {
            transform: rotate(540deg);
          }

          .menuListLevel2 {
            z-index: 2;
            height: auto;
            transform: scaleY(1);
          }
        }
      }
    }

    .menuListLevel2 {
      position: relative;
      display: block;
      height: 0;
      border: none;
      opacity: 1;
      transition: height 250ms ease-in-out 0s;

      a {
        padding: 0 var(--spacing-04) var(--spacing-04);
      }
    }

    .flexContainer {
      display: flex;
      justify-content: space-between;
    }
  }
}
