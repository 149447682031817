@use 'styles/base/mixins';

.headline {
  margin: 0 0 var(--spacing-04);

  @include mixins.devices(tablet) {
    margin-bottom: var(--spacing-06);
  }
}

.articleHead {
  display: flex;
  gap: var(--spacing-05);
  flex-direction: column;
}

.imageInfo {
  padding: var(--spacing-06);
  color: var(--white);
  background: var(--black);
}
