@use 'styles/base/mixins';

.wrapper {
  display: flex;
  overflow-x: auto;
}

.groupWrapper {
  flex: 1 0 302px;
  margin-bottom: var(--spacing-04);
  margin-left: var(--spacing-02);
  scroll-snap-align: start;

  @include mixins.devices(desktop) {
    flex: auto;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.group {
  height: auto;
  padding: var(--spacing-04);
  background: var(--gray-bright);
  transition: height 0.3s ease-out;

  &:first-child {
    margin-left: 0;
  }

  &.open .link:nth-of-type(5) ~ .link {
    height: auto;
    opacity: 1;
  }

  @include mixins.devices(desktop) {
    height: 100%;
  }
}

.headline {
  margin-bottom: var(--spacing-04);
  padding-bottom: var(--spacing-03);
  border-bottom: 2px solid var(--gray-light);
}

.link {
  display: block;
  overflow: hidden;
  transition:
    height 0.3s ease-out,
    opacity 0.3s ease-out;

  p {
    margin: 0 0 var(--spacing-04);
  }

  &:hover {
    text-decoration: underline;
  }

  &:nth-of-type(5) ~ .link {
    height: 0;
    opacity: 0;
  }
}

.showMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 var(--spacing-10) var(--spacing-01);
  color: var(--gray-dark);
  background: var(--gray-bright);
  cursor: pointer;
  border: none;

  svg {
    transition: transform 0.3s ease-out;
  }

  &.open svg {
    transform: rotate(540deg);
  }

  &.hide {
    display: none;
  }

  @include mixins.devices(desktop) {
    &.mobile {
      display: none;
    }
  }

  &.desktop {
    @include mixins.devices(mobile-only) {
      display: none;
    }

    @include mixins.devices(tablet-only) {
      display: none;
    }
  }
}
